<template>
	<!-- 领劵中心 -->
	<div id="plaza">
		<!-- 商品分类 -->
		<Classification
			:types="1"
			:navData="$store.state.navData"
			:sidebarData="$store.state.sidebarData"
		>
		</Classification>
		<!-- 顶部条幅图 -->
		<img
			class="plaza_banner"
			src="../../assets/img/plaza/plaza_banner.jpg"
			alt=""
		/>
		<div class="plaza_main">
			<div class="plaza_title">
				<img
					class="plaza_title_img"
					src="../../assets/img/plaza/yuanDian.png"
					alt=""
				/>
				<p class="plaza_title_text">领券广场</p>
				<img
					class="plaza_title_img"
					src="../../assets/img/plaza/yuanDian.png"
					alt=""
				/>
			</div>
			<div class="plaza_body">
				<div class="body_detail" v-for="item in cheapList" :key="item.id">
					<img
						class="detail_img"
						:src="item.url ? item.url : coupons_img"
						alt=""
					/>
					<div class="detail_rule">
						<div class="rule_textBox">
							<div class="plaza_text">
								<p>
									￥<span>{{ item.couponMoney }}</span>
								</p>
								<span class="plaza_textSpan"
									>满{{ item.consumptionPrice }}可用</span
								>
							</div>
							<p class="ruleTime">
								{{
									item.takeEffect == 1
										? item.startDate.substring(0, 10) +
										  '至' +
										  item.overDate.substring(0, 10)
										: '领取' + item.validDay + '天内有效'
								}}
							</p>
							<p>全部商品都适用</p>
							<p>[限品类] [全平台]</p>
							<img
								:class="
									item.status == 0
										? 'rule_textBox_img'
										: 'rule_textBox_img img_display'
								"
								src="../../assets/img/plaza/YiLingQu.png"
								alt=""
							/>
						</div>
					</div>
					<div class="detail_text" @click="getCheap(item.id, item.status)">
						{{ item.status == 0 ? '立即使用' : '立即领取' }}
					</div>
				</div>
				<div class="hint" v-if="cheapList.length <= 0">暂无可领取优惠券~~</div>
			</div>
		</div>
	</div>
</template>

<script>
import Classification from '@/components/classification.vue'
export default {
	name: 'GejMallPcIndex',
	components: {
		Classification,
	},

	data() {
		return {
			coupons_img: require('@/assets/img/coupons.png'),
			// 优惠券列表
			cheapList: [],
		}
	},

	mounted() {
		this.plazaInit()
	},

	methods: {
		// 查询优惠券列表
		plazaInit() {
			this.api
				.findByCouponLists({ token: this.$store.state.userData.token })
				.then((res) => {
					let dat = JSON.parse(JSON.stringify(res.data))
					let time = new Date().getTime()

					dat.forEach((element, index) => {
						if (element.takeEffect == 1) {
							let overDate = new Date(element.overDate).getTime()

							if (time > overDate) {
								dat.splice(index, 1)
							}
						}
					})

					this.cheapList = dat
				})
		},
		// 领取优惠券
		getCheap(id, status) {
			if (status == 0) {
				// 跳转商品列表

				this.$router.push({
					path: '/mall/goodsList',
					query: {
						coupon: id,
					},
				})
			} else {
				this.api
					.getCoupon({
						couponId: id,
						token: this.$store.state.userData.token,
					})
					.then((res) => {
						if (res.code == 0) {
							this.$message({
								message: '恭喜!成功领取优惠券',
								type: 'success',
							})
						}
						this.plazaInit()
					})
			}
		},
	},
}
</script>

<style lang="scss" scoped>
#plaza {
	.plaza_banner {
		width: 100%;
		height: 500px;
	}

	.plaza_main {
		margin: 0 auto;
		width: 1200px;
		// height: 1563px;
		overflow: hidden;

		.plaza_title {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 59px;

			.plaza_title_text {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #333333;
				margin-left: 10px;
				margin-right: 10px;
			}

			.plaza_title_img {
				width: 36px;
				height: 20px;
			}
		}

		.plaza_body {
			width: 100%;
			min-height: 292px;
			float: left;

			.hint {
				// color: #f4f4f4;
				text-align: center;
				line-height: 292px;
			}

			.body_detail {
				width: 360px;
				height: 146px;
				background: white;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin-top: 25px;
				margin-bottom: 25px;
				float: left;
				margin-left: 20px;
				margin-right: 20px;

				.detail_img {
					width: 100px;
					height: 100px;
				}

				.detail_rule {
					.rule_textBox {
						margin-left: 23px;
						position: relative;

						.plaza_text {
							display: flex;
							align-items: center;
							margin-right: 25px;

							// margin-bottom: 15px;
							p {
								font-size: 16px;
								color: red;

								span {
									font-size: 25px;
									margin-right: 6px;
								}
							}

							.plaza_textSpan {
								width: 83px;
								height: 26px;
								text-align: center;
								line-height: 26px;
								background: #feeae1;
								border-radius: 5px;
								font-size: 14px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #e62129;
							}
						}

						.ruleTime {
							font-size: 13px;
							margin-top: 2px;
							margin-bottom: 2px;
						}

						p {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #666666;
						}

						.rule_textBox_img {
							position: absolute;
							width: 60px;
							height: 54px;
							top: 55px;
							right: 0;
						}

						.img_display {
							display: none;
						}
					}
				}

				.detail_text {
					cursor: pointer;
					width: 50px;
					height: 146px;
					background: url('../../assets/img/plaza/red_juXing.png');
					word-wrap: break-word;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
					line-height: 1.5em;
					padding-left: 20px;
					padding-top: 13px;
					// 文字竖排列
					letter-spacing: 20px;
					font-size: 20px;
				}
			}
		}
	}
}
</style>